const baseUrl = "/api/v1/admin"; //https://api.fxranking.com

const createOptions = (body, method, isForm = false) => {
  let options = {
    method: method,
    body: body,
    headers: {
      from: "cabinet",
      cabinet: useApp().appName,
      accept: "application/json",
      "content-type": "application/json",
      authorization: "Bearer " + useAuth().getAuthToken(),
      lang: useRuntimeConfig().app.lang,
      // "Locale": i18n.locale, // TODO : fix this
    },
    timeout: 30000,
  };

  if (isForm) {
    options.headers["content-type"] = "multipart/form-data";
  }

  return options;
};

const createFormOptions = (json) => {
  const form = new FormData();

  for (const key in json) {
    if (
      typeof json[key] === "string" ||
      json[key] === "number" ||
      json[key] === "boolean"
    ) {
      form.append(key, json[key]);
    } else if (json[key] instanceof File) {
      form.append(key, json[key]);
    } else if (typeof json[key] === "object") {
      form.append(key, JSON.stringify(json[key]));
    } else {
      form.append(key, json[key]);
    }
  }

  let options = {
    method: "POST",
    body: form,
    headers: {
      authorization: "Bearer " + useAuth().getAuthToken(),
    },
  };

  return options;
};

const get = async (route, body) => {
  return $fetch(`${baseUrl}${route}`, createOptions(body, "GET")).then((err) =>
    handleResponse(err, route)
  );
};

const post = async (route, body) => {
  return $fetch(`${baseUrl}${route}`, createOptions(body, "POST")).then((err) =>
    handleResponse(err, route)
  );
};

const form = async (route, body) => {
  return $fetch(`${baseUrl}${route}`, createFormOptions(body)).then((err) =>
    handleResponse(err, route)
  );
};

const handleResponse = (response, route) => {
  const { status, data } = response;

  switch (status) {
    case 200:
    case 201:
    case 202:
      return response;
    case 422:
      const errors = data.message;
      const error_keys = Object.keys(errors);
      const first_error_message = errors[error_keys[0]][0];
      useSwal().fire({
        icon: "error",
        title: "Error",
        text: first_error_message,
      });
      break;
    case 403:
      useSwal().fire({
        icon: "error",
        title: "Error",
        text: "Access denied!",
      });
      break;
    case 401:
      if (!route.includes("auth")) {
        useRouter().push("/logout");
      }

      break;
    case 490:
      useRouter().push("/auth/g2fa");
      break;
    case 500:
      useSwal().fire({
        icon: "error",
        title: "Error",
        text: "Server error, please try again later!",
      });
      break;
    case 400:
    case 469:
    case 480:
    case 570:
      useSwal().fire({
        icon: "error",
        title: "Error",
        text: data.message,
      });
      break;
  }

  return Promise.reject(response);
};

const getSearchTable = async (route, body = {}) => {
  return $fetch(`${baseUrl}${route}`, createOptions(body, "POST")).then((res) =>
    handleResponse(res, route)
  );
};

export const useApi = () => ({
  auth: {
    login: (email, password) => post(`/auth/login`, { email, password }),

    logout: () => post(`/auth/logout`),
  },

  user: {
    all: () => get(`/user`),

    get: (id) => get(`/user/${id}`),

    me: () => get(`/user/me`),

    create: (
      name,
      username,
      email,
      password,
      pass_confirmation,
      avatar_id,
      bio
    ) =>
      post(`/user/create`, {
        name,
        username,
        email,
        password,
        pass_confirmation,
        avatar_id,
        bio,
      }),

    update: (
      id,
      name,
      username,
      email,
      password,
      pass_confirmation,
      avatar_id,
      bio
    ) =>
      post(`/user/${id}/update`, {
        name,
        username,
        email,
        password,
        pass_confirmation,
        avatar_id,
        bio,
      }),

    remove: (id) => post(`/user/${id}/remove`),
  },

  lang: {
    all: () => get(`/lang`),

    get: (id) => get(`/lang/${id}`),

    create: (key, value) => post(`/lang/create`, { key, value }),

    update: (id, key, value) => post(`/lang/${id}/update`, { key, value }),
  },

  media: {
    store: (file, disk) => form(`/media`, { file: file, disk }),
  },

  categories: {
    all: () => get(`/category`),

    types: () => get(`/category/types`),

    get: (id) => get(`/category/${id}`),

    create: (title, subtitle, slug, type, image_id, description, meta_tags) =>
      post(`/category/create`, {
        title,
        subtitle,
        slug,
        type,
        image_id,
        description,
        meta_tags,
      }),

    update: (
      id,
      title,
      subtitle,
      slug,
      type,
      image_id,
      description,
      meta_tags
    ) =>
      post(`/category/${id}/update`, {
        title,
        subtitle,
        slug,
        image_id,
        type,
        description,
        meta_tags,
      }),

    remove: (id) => post(`/category/${id}/remove`),
  },

  website: {
    all: () => get(`/website`),

    get: (id) => get(`/website/${id}`),

    create: (
      name,
      top_brokers,
      setting,
      about_us,
      terms,
      faq,
      contact_us_email,
      brokers_in_nav
    ) =>
      post(`/website/create`, {
        name,
        top_brokers,
        setting,
        about_us,
        terms,
        faq,
        contact_us_email,
        brokers_in_nav,
      }),

    update: (
      id,
      name,
      top_brokers,
      setting,
      about_us,
      terms,
      faq,
      contact_us_email,
      brokers_in_nav
    ) =>
      post(`/website/${id}/update`, {
        name,
        top_brokers,
        setting,
        about_us,
        terms,
        faq,
        contact_us_email,
        brokers_in_nav,
      }),

    remove: (id) => post(`/website/${id}/remove`),
  },

  guide: {
    all: () => get(`/guide`),

    get: (id) => get(`/guide/${id}`),

    duplicate: (id) => post(`/guide/${id}/duplicate`),
    
    duplicateMulti: (ids, website_id) => post(`/guide/duplicate-multi`, { ids, website_id }),

    removeMulti: (ids) => post(`/guide/remove-multi`, { ids }),
    
    changeStatusMulti: (ids) => post(`/guide/change-status-multi`, { ids }),

    create: (
      title,
      description,
      slug,
      thumbnail_id,
      category_id,
      website_id,
      meta_tags,
      body,
      user_id,
      is_published,
      thumbnail_alt,
      keywords,
      time_to_read,
      schema
    ) =>
      post(`/guide/create`, {
        title,
        description,
        slug,
        thumbnail_id,
        category_id,
        website_id,
        meta_tags,
        body,
        user_id,
        is_published,
        thumbnail_alt,
        keywords,
        time_to_read,
        schema,
      }),

    update: (
      id,
      title,
      description,
      slug,
      thumbnail_id,
      category_id,
      website_id,
      meta_tags,
      body,
      user_id,
      is_published,
      thumbnail_alt,
      keywords,
      time_to_read,
      schema
    ) =>
      post(`/guide/${id}/update`, {
        title,
        description,
        slug,
        thumbnail_id,
        category_id,
        website_id,
        meta_tags,
        body,
        user_id,
        is_published,
        thumbnail_alt,
        keywords,
        time_to_read,
        schema,
      }),

    remove: (id) => post(`/guide/${id}/remove`),
  },

  education: {
    all: () => get(`/education`),

    get: (id) => get(`/education/${id}`),

    duplicate: (id) => post(`/education/${id}/duplicate`),

    duplicateMulti: (ids, website_id) => post(`/education/duplicate-multi`, { ids, website_id }),

    removeMulti: (ids) => post(`/education/remove-multi`, { ids }),
    
    changeStatusMulti: (ids) => post(`/education/change-status-multi`, { ids }),

    create: (
      title,
      description,
      slug,
      thumbnail_id,
      category_id,
      website_id,
      meta_tags,
      body,
      user_id,
      is_published,
      thumbnail_alt,
      keywords,
      time_to_read,
      schema
    ) =>
      post(`/education/create`, {
        title,
        description,
        slug,
        thumbnail_id,
        category_id,
        website_id,
        meta_tags,
        body,
        user_id,
        is_published,
        thumbnail_alt,
        keywords,
        time_to_read,
        schema,
      }),

    update: (
      id,
      title,
      description,
      slug,
      thumbnail_id,
      category_id,
      website_id,
      meta_tags,
      body,
      user_id,
      is_published,
      thumbnail_alt,
      keywords,
      time_to_read,
      schema
    ) =>
      post(`/education/${id}/update`, {
        title,
        description,
        slug,
        thumbnail_id,
        category_id,
        website_id,
        meta_tags,
        body,
        user_id,
        is_published,
        thumbnail_alt,
        keywords,
        time_to_read,
        schema,
      }),

    remove: (id) => post(`/education/${id}/remove`),
  },

  broker: {
    all: () => get(`/broker`),
    
    baseBrokers: () => get(`/broker/base`),

    allByWebsite: (id) => get(`/broker/website/${id}`),

    getOrders: (website_id) => get(`/broker/${website_id}/get-orders`),

    order: (items) => post(`/broker/order`, { items }),

    duplicate: (id) => post(`/broker/${id}/duplicate`),

    duplicateMulti: (ids, website_id) => post(`/broker/duplicate-multi`, { ids, website_id }),

    removeMulti: (ids) => post(`/broker/remove-multi`, { ids }),
    
    changeStatusMulti: (ids) => post(`/broker/change-status-multi`, { ids }),
    create: (
      name,
      country_id,
      user_id,
      website_id,
      slug,
      website_address,
      leverage,
      min_deposit,
      spread,
      rank,
      regulation,
      year_established,
      regulated_type,
      image_id,
      offices,
      instruments,
      about,
      pros,
      cons,
      review,
      licences,
      platforms,
      account_type,
      currency_pairs,
      payment_methods_desc,
      contact_info,
      meta_tags,
      allowed_countries,
      disallowed_countries,
      payment_methods,
      type,
      platforms_website,
      allowed_countries_desc,
      is_published,
      faq,
      licences_desc,
      platforms_desc,
      contact_info_desc,
      instruments_desc,
      fees,
      bonus,
      education,
      schema
    ) =>
      post(`/broker/create`, {
        name,
        country_id,
        user_id,
        website_id,
        slug,
        website_address,
        leverage,
        min_deposit,
        spread,
        rank,
        regulation,
        year_established,
        regulated_type,
        image_id,
        offices,
        instruments,
        about,
        pros,
        cons,
        review,
        licences,
        platforms,
        account_type,
        currency_pairs,
        payment_methods_desc,
        contact_info,
        meta_tags,
        allowed_countries,
        disallowed_countries,
        payment_methods,
        type,
        platforms_website,
        allowed_countries_desc,
        is_published,
        faq,
        licences_desc,
        platforms_desc,
        contact_info_desc,
        instruments_desc,
        fees,
        bonus,
        education,
        schema,
      }),

    get: (id) => get(`/broker/${id}`),

    update: (
      id,
      name,
      country_id,
      user_id,
      website_id,
      slug,
      website_address,
      leverage,
      min_deposit,
      spread,
      rank,
      regulation,
      year_established,
      regulated_type,
      image_id,
      offices,
      instruments,
      about,
      pros,
      cons,
      review,
      licences,
      platforms,
      account_type,
      currency_pairs,
      payment_methods_desc,
      contact_info,
      meta_tags,
      allowed_countries,
      disallowed_countries,
      payment_methods,
      type,
      platforms_website,
      allowed_countries_desc,
      is_published,
      faq,
      licences_desc,
      platforms_desc,
      contact_info_desc,
      instruments_desc,
      fees,
      bonus,
      education,
      schema
    ) =>
      post(`/broker/${id}/update`, {
        name,
        country_id,
        user_id,
        website_id,
        slug,
        website_address,
        leverage,
        min_deposit,
        spread,
        rank,
        regulation,
        year_established,
        regulated_type,
        image_id,
        offices,
        instruments,
        about,
        pros,
        cons,
        review,
        licences,
        platforms,
        account_type,
        currency_pairs,
        payment_methods_desc,
        contact_info,
        meta_tags,
        allowed_countries,
        disallowed_countries,
        payment_methods,
        type,
        platforms_website,
        allowed_countries_desc,
        is_published,
        faq,
        licences_desc,
        platforms_desc,
        contact_info_desc,
        instruments_desc,
        fees,
        bonus,
        education,
        schema,
      }),

    remove: (id) => post(`/broker/${id}/remove`),
  },

  brokerPost: {
    all: () => get(`/broker-post`),

    get: (id) => get(`/broker-post/${id}`),

    duplicate: (id) => post(`/broker-post/${id}/duplicate`),

    duplicateMulti: (ids, website_id) => post(`/broker-post/duplicate-multi`, { ids, website_id }),

    removeMulti: (ids) => post(`/broker-post/remove-multi`, { ids }),
    
    changeStatusMulti: (ids) => post(`/broker-post/change-status-multi`, { ids }),

    create: (
      title,
      description,
      slug,
      thumbnail_id,
      broker_id,
      website_id,
      meta_tags,
      body,
      user_id,
      is_published,
      thumbnail_alt,
      keywords,
      time_to_read,
      schema
    ) =>
      post(`/broker-post/create`, {
        title,
        description,
        slug,
        thumbnail_id,
        broker_id,
        website_id,
        meta_tags,
        body,
        user_id,
        is_published,
        thumbnail_alt,
        keywords,
        time_to_read,
        schema,
      }),

    update: (
      id,
      title,
      description,
      slug,
      thumbnail_id,
      broker_id,
      website_id,
      meta_tags,
      body,
      user_id,
      is_published,
      thumbnail_alt,
      keywords,
      time_to_read,
      schema
    ) =>
      post(`/broker-post/${id}/update`, {
        title,
        description,
        slug,
        thumbnail_id,
        broker_id,
        website_id,
        meta_tags,
        body,
        user_id,
        is_published,
        thumbnail_alt,
        keywords,
        time_to_read,
        schema,
      }),

    remove: (id) => post(`/broker-post/${id}/remove`),
  },

  comparisons: {
    all: () => get(`/comparison`),

    get: (id) => get(`/comparison/${id}`),

    duplicate: (id) => post(`/comparison/${id}/duplicate`),

    duplicateMulti: (ids, website_id) => post(`/comparison/duplicate-multi`, { ids, website_id }),
    
    removeMulti: (ids) => post(`/comparison/remove-multi`, { ids }),
    
    changeStatusMulti: (ids) => post(`/comparison/change-status-multi`, { ids }),
    create: (
      title,
      description,
      slug,
      thumbnail_id,
      is_published,
      website_id,
      meta_tags,
      body,
      user_id,
      thumbnail_alt,
      keywords,
      time_to_read,
      schema
    ) =>
      post(`/comparison/create`, {
        title,
        description,
        slug,
        thumbnail_id,
        is_published,
        website_id,
        meta_tags,
        body,
        user_id,
        thumbnail_alt,
        keywords,
        time_to_read,
        schema,
      }),

    update: (
      id,
      title,
      description,
      slug,
      thumbnail_id,
      is_published,
      website_id,
      meta_tags,
      body,
      user_id,
      thumbnail_alt,
      keywords,
      time_to_read,
      schema
    ) =>
      post(`/comparison/${id}/update`, {
        title,
        description,
        slug,
        thumbnail_id,
        is_published,
        website_id,
        meta_tags,
        body,
        user_id,
        thumbnail_alt,
        keywords,
        time_to_read,
        schema,
      }),

    remove: (id) => post(`/comparison/${id}/remove`),
  },

  comment: {
    all: () => get(`/comment`),

    get: (id) => get(`/comment/${id}`),

    update: (id, status) => post(`/comment/${id}/update`, { status }),
  },

  country: {
    all: () => get(`/country`),

    get: (id) => get(`/country/${id}`),

    create: (name, flag) => post(`/country/create`, { name, flag }),

    update: (id, name, flag) => post(`/country/${id}/update`, { name, flag }),
  },

  paymentMethod: {
    all: () => get(`/payment-method`),

    get: (id) => get(`/payment-method/${id}`),

    create: (name, icon, icon2) =>
      post(`/payment-method/create`, { name, icon, icon2 }),

    update: (id, name, icon, icon2) =>
      post(`/payment-method/${id}/update`, { name, icon, icon2 }),
  },

  contactUs: {
    all: () => get(`/contact-us`),

    get: (id) => get(`/contact-us/${id}`),

    allByWebsite: (id) => get(`/contact-us/website/${id}`),

    update: (id) => post(`/contact-us/${id}/update`),
  },

  redirect: {
    all: () => get(`/redirect`),

    get: (id) => get(`/redirect/${id}`),

    create: (old_url, new_url, website_id) =>
      post(`/redirect/create`, {
        old_url,
        new_url,
        website_id,
      }),

    update: (id, old_url, new_url, website_id) =>
      post(`/redirect/${id}/update`, {
        old_url,
        new_url,
        website_id,
      }),

    remove: (id) => post(`/redirect/${id}/remove`),
  },

  get,
  form,
  getSearchTable,
});
